import React from 'react'
import Navigation from '../Navigation'
import QuoteRequest from '../QuoteRequest'
import Footer from '../Footer'

const Quote = () => {
  return (
    <div>
        <Navigation />
<QuoteRequest />
<Footer />
    </div>
  )
}

export default Quote