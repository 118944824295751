import React from 'react'
import ContactUs from '../ContactUs'
import Navigation from '../Navigation'
import Footer from '../Footer'


const Contact = () => {
  return (
    <div className='contact'>
<Navigation />
<ContactUs />
<Footer />

    </div>
  )
}

export default Contact