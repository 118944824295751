import React from 'react'
import Navigator from '../Navigation'
import AboutSlider from '../AboutSlider'
import WhatWeAre from '../WhatWeAre'
import Whatwedo from '../WhatWeDo'
import Footer from '../Footer'
import CalltoAction from '../CalltoAction'
import Aboutiso from '../Aboutiso'

const About = () => {
  return (
  <>
  <Navigator/>
  <AboutSlider/>
  <WhatWeAre/>
  <Aboutiso />
  <Whatwedo/>
  <CalltoAction/>
  <Footer/>
  </>
  )
}

export default About