import React from 'react'
import Navigation from '../Navigation'
import Career from './Career'
import Footer from '../Footer'

const SkytechCareer = () => {
  return (
    <div>
      <Navigation />
      <Career />
      <Footer />
    </div>
  )
}

export default SkytechCareer